import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faFilePdf, faPodcast } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Helmet from "../components/Helmet";

function Nutrition() {
    return (
        <>
            <Helmet title={'Recipes & Nutrition Education'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Recipes &amp; Nutrition Education</h1></div>
            </Container>
            <Container className="my-5 text-center">
                <h2>Recipe Challenge: <small>Using Farm Fresh Ingredients</small></h2>
            </Container>
            <Container fluid className='px-md-5 recipes-boxes'>
                <Row className="mb-3 justify-content-between">
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/eggplant-stew.png`} className="w-100" alt="The Recipe Challenge: Eggplant Stew" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Eggplant Stew</h4>
                                <ul>
                                    <li>White Eggplants</li>
                                    <li>Tomatoes</li>
                                    <li>Bell Peppers</li>
                                    <li>Red Onions</li>
                                    <li>Carrots</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>White eggplants, also known as white aubergines, have a unique place in culinary and horticultural history. Unlike the more common purple varieties, white eggplants were one of the earliest cultivated types, originating in India and Southeast Asia before spreading to the Middle East, Europe, and beyond. They were named for their egg-like shape and pale color, distinguishing them from other varieties that contain fiber, aid digestion, and are low in calories, making them a healthy addition to a balanced diet.</p>
                        <p>Website - <a href="https://www.allrecipes.com/recipe/284562/white-eggplant-saute/" target="_blank" rel="noopener noreferrer" className="text-info">White Eggplant Sauté <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - MAPISHI FOOD LIFE <a href="https://www.youtube.com/watch?v=qsS4R1QKZ6c" target="_blank" rel="noopener noreferrer" className="text-info">White Eggplant Recipes/Two Dishes From White Eggplant <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - Village Food Village <a href="https://www.youtube.com/watch?v=uIASVBSpsCo" target="_blank" rel="noopener noreferrer" className="text-info">Cooking White Brinjal Masala Curry |Baingan masala recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/open-faced-tomato-sandwich.png`} className="w-100" alt="The Recipe Challenge: Open Faced Tomato Sandwich" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Open Faced Tomato Sandwich</h4>
                                <ul>
                                    <li>Fresh Bread</li>
                                    <li>Tomatoes</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The open-faced tomato sandwich boasts a history as rich as its flavor. While its exact origin is unknown, it likely evolved from using bread as a base for food, as seen in practices like the 15th-century English "trencher." Open-faced sandwiches became a staple in Scandinavia, featuring various toppings.</p>
                        <p>The first mention of a tomato sandwich appears in the 1911 "Virginia Chronicle," solidifying its place in Southern cuisine. From backyard picnics to fancy restaurants, the open-faced tomato sandwich remains a delightful celebration of fresh, seasonal simplicity.</p>
                        <p>Website - <a href="https://www.npr.org/2011/01/04/132627711/the-art-of-the-danish-open-face-sandwich" target="_blank" rel="noopener noreferrer" className="text-info">The Art Of The Danish Open-Face Sandwich <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://hipfoodiemom.com/2020/07/24/best-open-faced-tomato-sandwich/" target="_blank" rel="noopener noreferrer" className="text-info">Best Open Faced Tomato Sandwich <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Sandwiches of History <a href="https://www.youtube.com/watch?v=XSprnHybJEQ" target="_blank" rel="noopener noreferrer" className="text-info">Furikake Tomato Sandwich (recent history) on Sandwiches of History⁣                        <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/faba-fava-beans-with-cilantro.png`} className="w-100" alt="The Recipe Challenge: Faba/Fava Beans with Cilantro" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Faba/Fava Beans with Cilantro</h4>
                                <ul>
                                    <li>Faba/Fava Beans</li>
                                    <li>Cilantro</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Fava/Faba beans, also known as broad beans, have a long and rich history dating back to ancient times. Their origins trace back to the Near East and Mediterranean region, with evidence of their cultivation as early as 6000 BCE. Archaeological findings suggest that they were among the first crops to be domesticated by early agricultural societies, making them one of the oldest cultivated plants. </p>
                        <p>Rich in protein, fiber, and essential nutrients, fava beans promote heart health, improve digestion, and support weight management.</p>
                        <p>Website - <a href="https://www.food.com/recipe/fava-beans-with-cilantro-369921" target="_blank" rel="noopener noreferrer" className="text-info">FAVA BEANS WITH CILANTRO <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Yi's Sichuan Kitchen <a href="https://www.youtube.com/watch?v=XSprnHybJEQ" target="_blank" rel="noopener noreferrer" className="text-info">Vegan Broad Bean Recipe |How to prepare and cook fresh fava beans <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/tomato-gnocchi-kebabs.png`} className="w-100" alt="The Recipe Challenge: Tomato Gnocchi Kebabs" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Tomato Gnocchi Kebabs</h4>
                                <ul>
                                    <li>Cherry Tomatoes</li>
                                    <li>Parsley</li>
                                    <li>Red Onions</li>
                                    <li>Basil</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Tomato Gnocchi Kebabs are a fun and flavorful way to enjoy a vegetarian meal. They combine pillowy gnocchi, juicy cherry tomatoes, and crisp onions, all grilled to perfection on skewers. This technique has been around for centuries, dating back to civilizations like Mesopotamia. This dish is rich in antioxidants and provides a good source of Vitamin C and potassium from the tomatoes, while the gnocchi offers carbohydrates for energy and essential nutrients. Including tomatoes in your diet can boost heart health and reduce inflammation, making these kebabs a nutritious and tasty option.</p>
                        <p>Website - <a href="https://www.punchfork.com/recipe/Tomato-Gnocchi-Kebabs-Delish" target="_blank" rel="noopener noreferrer" className="text-info">Tomato Gnocchi Kebabs <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - imapampchef <a href="https://youtube.com/shorts/SZ5fsd-Zrtw?feature=shared" target="_blank" rel="noopener noreferrer" className="text-info">Tomato Gnocchi Kebabs <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/sweet-potato-spring-onion-feta-salad.png`} className="w-100" alt="The Recipe Challenge: Sweet Potato, Spring Onion & Feta Salad" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Sweet Potato, Spring Onion &amp; Feta Salad</h4>
                                <ul>
                                    <li>Sweet Potatoes</li>
                                    <li>Onions</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Sweet potatoes originated in Central and South America over 5,000 years ago and were cultivated by indigenous peoples long before European exploration. </p>
                        <p>Christopher Columbus brought the plant back with him in the late 15th century, subsequently introducing it to Europe.</p>
                        <p>Sweet potatoes are a great source of fiber, antioxidants, vitamin C, vitamin A, potassium and minerals.</p>
                        <p>Website - <a href="https://www.bbcgoodfood.com/recipes/sweet-potato-spring-onion-feta-salad" target="_blank" rel="noopener noreferrer" className="text-info">Sweet potato, spring onion &amp; feta salad <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Divas Can Cook <a href="https://www.youtube.com/watch?v=BwwDcp64fes" target="_blank" rel="noopener noreferrer" className="text-info">Sweet Potato Feta Spinach Salad <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/southern-cabbage.png`} className="w-100" alt="The Recipe Challenge: Southern Cabbage" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Southern Cabbage</h4>
                                <ul>
                                    <li>Cabbage</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Southern Cabbage history traces back to African and European culinary traditions, with recipes evolving over centuries. From slavery to modern cuisine, it's a staple in dishes like coleslaw and collard greens, reflecting a rich cultural heritage.</p>
                        <p>Rich in vitamins, fiber, and antioxidants, cabbage offers numerous health benefits, including supporting digestion, boosting immunity, and potentially reducing the risk of certain chronic diseases.</p>
                        <p>Website - <a href="https://cookswithsoul.com/southern-cabbage-with-smoked-turkey/" target="_blank" rel="noopener noreferrer" className="text-info">Southern Cabbage Recipe (Boiled Cabbage) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Audio - Shaunda Necole <a href="https://podcasts.apple.com/us/podcast/the-soul-food-pod/id1679234434?i=1000608061086" target="_blank" rel="noopener noreferrer" className="text-info">The Soul Food Pod - Best Southern Cabbage Recipes <FontAwesomeIcon icon={faPodcast} /></a></p>
                        <p>Video - Divas Can Cook <a href="https://www.youtube.com/watch?v=g2eeUSIwF0o" target="_blank" rel="noopener noreferrer" className="text-info">Grandma's Southern Cabbage Recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/classic-deviled-eggs.png`} className="w-100" alt="The Recipe Challenge: Classic Deviled Eggs" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Classic Deviled Eggs</h4>
                                <ul>
                                    <li>Eggs</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Deviled Eggs, a timeless culinary delight traced back to ancient Rome, is believed to be a favorite appetizer served at lavish feasts. Over the centuries, this simple yet elegant dish has become a staple at gatherings and celebrations. It's a smooth, creamy filling and the perfect balance of tangy and savory flavors.</p>
                        <p>Pasture-raised eggs come from hens that roam freely outdoors, foraging for a diverse diet of grasses and insects. This results in eggs with a rich, flavorful taste and higher nutritional quality.</p>
                        <p>Website - <a href="https://www.foodnetwork.com/recipes/classic-deviled-eggs-recipe-1911032" target="_blank" rel="noopener noreferrer" className="text-info">Classic Deviled Eggs <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Martha Stewart <a href="https://www.youtube.com/watch?v=g2eeUSIwF0o" target="_blank" rel="noopener noreferrer" className="text-info">Classic Deviled Eggs- Martha Stewart <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/pumpkin-soup.png`} className="w-100" alt="The Recipe Challenge: Pumpkin Soup" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Pumpkin Soup</h4>
                                <ul>
                                    <li>Butternut/Pumpkin</li>
                                    <li>Yellow Onion</li>
                                    <li>Scallions</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Pumpkin soup has a rich history dating back to Native American tribes who cultivated and consumed pumpkins for centuries. It became a popular dish in North America during the early colonial period when European settlers integrated it into their culinary traditions.</p>
                        <p>This hearty soup is delicious and nutritious, as pumpkins have vitamins, minerals, and antioxidants that support eye health boost immunity, and promote heart health.</p>
                        <p>Website - <a href="https://www.recipetineats.com/classic-pumpkin-soup/" target="_blank" rel="noopener noreferrer" className="text-info">Pumpkin Soup - Classic and Easy <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Chef Jack Ovens <a href="https://www.youtube.com/watch?v=GA-fCgJykEw" target="_blank" rel="noopener noreferrer" className="text-info">How To Make The Best Creamy Pumpkin Soup <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - Recipes by Carina  <a href="https://www.youtube.com/watch?v=RPGBMkN-_Ys" target="_blank" rel="noopener noreferrer" className="text-info">Roasted Pumpkin Soup Recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/puerto-rican-sofrito.png`} className="w-100" alt="The Recipe Challenge: Puerto Rican Sofrito" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Puerto Rican Sofrito</h4>
                                <ul>
                                    <li>Onions</li>
                                    <li>Garlic</li>
                                    <li>Cilantro</li>
                                    <li>Bell Peppers</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Puerto Rican Sofrito is a vibrant and aromatic condiment at the heart of Puerto Rican cuisine. It is known for its rich blend of garlic, onions, bell peppers, cilantro, and various spices. Beyond its culinary allure, this flavorful sauce also offers several health benefits is packed with antioxidant-rich herbs and vegetables that can help combat inflammation and support overall immune system health. Additionally, the garlic in Sofrito has cardiovascular benefits, including improved heart health and cholesterol management.</p>
                        <p>Website - <a href="https://www.eatingwell.com/recipe/276910/puerto-rican-sofrito/" target="_blank" rel="noopener noreferrer" className="text-info">Puerto Rican Sofrito <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.tiktok.com/@themoodyfoody/video/7233465272412278062" target="_blank" rel="noopener noreferrer" className="text-info">Puerto Rican Sofrito <FontAwesomeIcon icon={faTiktok} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/algamja-jorim.png`} className="w-100" alt="The Recipe Challenge: Algamja-Jorim / Braised Baby Potatoes" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Algamja-Jorim / Braised Baby Potatoes</h4>
                                <ul>
                                    <li>Small Potatoes</li>
                                    <li>Garlic</li>
                                    <li>Honey</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Algamja-jorim is a traditional Korean dish made from braised baby potatoes in a sweet and savory soy-based sauce. The dish is cherished not only for its delightful taste but also for its pleasing texture. Honey, often added for sweetness, contains antioxidants, vitamins, and minerals, promoting digestive health and potentially helping in soothing allergies. On the other hand, potatoes are a rich source of vitamins, minerals, and fiber, which can help maintain heart health, improve digestion, and support the immune system.</p>
                        <p>Website - <a href="https://thefoodietakesflight.com/korean-braised-potatoes-gamja-jorim/" target="_blank" rel="noopener noreferrer" className="text-info">Korean Braised Potatoes / (Gamja Jorim) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.maangchi.com/recipe/algamja-jorim" target="_blank" rel="noopener noreferrer" className="text-info">Braised baby potatoes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://mykoreankitchen.com/gamja-jorim-korean-braised-potatoes/" target="_blank" rel="noopener noreferrer" className="text-info">GAMJA JORIM (KOREAN BRAISED POTATOES) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=Sjk54-JORj0" target="_blank" rel="noopener noreferrer" className="text-info">How to cook Braised Baby Potatoes(Algamja-jorim) <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/bharli-vangi.png`} className="w-100" alt="The Recipe Challenge: Bharli Vangi / Baby Eggplant Curry" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Bharli Vangi / Baby Eggplant Curry</h4>
                                <ul>
                                    <li>Small Eggplants</li>
                                    <li>Cilantro</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Eggplant, known as aubergine or brinjal in many parts of the world, is a universal vegetable with deep historical roots tracing over 1,500 years to its native regions of South Asia. Eggplants are rich in fiber, vitamins, and minerals, boasting benefits such as supporting heart health and controlling blood sugar levels.</p>
                        <p>Translated as "stuffed eggplant," Bharli Vangi, a traditional Maharashtrian stuffed eggplant dish, exemplifies the vegetable's rich cultural significance in India. </p>
                        <p>Website - <a href="https://www.cookwithmanali.com/baghare-baingan/" target="_blank" rel="noopener noreferrer" className="text-info">Baghare Baingan - Eggplant Curry With Coconut &amp; Peanuts <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://healthiersteps.com/recipe/baby-eggplant-coconut-curry/" target="_blank" rel="noopener noreferrer" className="text-info">Baby Eggplant Coconut Curry <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Manjula's Kitchen <a href="https://www.youtube.com/watch?app=desktop&v=nwEcMpcIQ_0" target="_blank" rel="noopener noreferrer" className="text-info">Hyderabadi Bagara Baingan (baby eggplant curry) Recipe by Manjula <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/vegetable-soup-with-cabbage.png`} className="w-100" alt="The Recipe Challenge: Vegetable Soup with Cabbage" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Vegetable Soup with Cabbage</h4>
                                <ul>
                                    <li>Green Cabbage</li>
                                    <li>Yellow Potatoes</li>
                                    <li>Thyme</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Green cabbage, or Brassica oleracea, has been cultivated for over 4,000 years. It was initially grown in the Mediterranean region and later spread to Europe and other parts of the world. </p>
                        <p>This leafy vegetable is rich in vitamin C, K, and fiber, which can help improve digestion, strengthen the immune system, and reduce inflammation. Regular consumption of green cabbage also reduces the risk of chronic diseases such as cancer and heart disease.</p>
                        <p>Website - <a href="https://www.eatingwell.com/recipe/7921759/one-pot-vegetable-soup-with-cabbage/" target="_blank" rel="noopener noreferrer" className="text-info">One-Pot Vegetable Soup with Cabbage <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.eatingwell.com/gallery/8044899/cabbage-recipes-youll-want-to-make-forever/" target="_blank" rel="noopener noreferrer" className="text-info">26 Cabbage Recipes You'll Want to Make Forever <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.eatingwell.com/gallery/7996405/september-dinner-recipes/" target="_blank" rel="noopener noreferrer" className="text-info">30 Dinner Recipes to Make in September <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Downshiftology <a href="https://www.youtube.com/watch?v=lRI3yNHY7NE" target="_blank" rel="noopener noreferrer" className="text-info">Cabbage Soup <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/southwestern-corn-chowder.png`} className="w-100" alt="The Recipe Challenge: Southwestern Corn Chowder" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Southwestern Corn Chowder</h4>
                                <ul>
                                    <li>Sweet Corn</li>
                                    <li>Red Onions</li>
                                    <li>Red Potatoes</li>
                                    <li>Celery</li>
                                    <li>Pepper</li>
                                    <li>Cilantro</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Corn originated in Mexico over 9,000 years ago and is known by its original name "maize" in many parts of the world. Native Americans grew and harvested this crop as a main source of food.</p>
                        <p>Corn is rich in fiber, vitamins and minerals.</p>
                        <p>Website - <a href="https://cookieandkate.com/vegetarian-corn-chowder-recipe/" target="_blank" rel="noopener noreferrer" className="text-info">Southwestern Corn Chowder <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/radish-pico.png`} className="w-100" alt="The Recipe Challenge: Radish Pico" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Radish Pico</h4>
                                <ul>
                                    <li>Radish</li>
                                    <li>Jalapeño or Serrano</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Radish Pico, also known as "pico de gallo with radishes," is a flavorful variant of the traditional pico de gallo salsa originating from Mexico. </p>
                        <p>Radish, known scientifically as Raphanus sativus, has a rich history spanning thousands of years and has been cultivated for its culinary and medicinal properties. Believed to have originated in Southeast Asia, radishes were highly prized in ancient Egypt, Greece, and Rome and considered an essential part of their diets.</p>
                        <p>Radish packed with essential nutrients, including vitamin C, potassium, and fiber.</p>
                        <p>Website - <a href="https://patijinich.com/radish-pico/" target="_blank" rel="noopener noreferrer" className="text-info">Radish Pico Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/swiss-chard-with-spicy-garlic-oil.png`} className="w-100" alt="The Recipe Challenge: Swiss Chard with Spicy Garlic Oil" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Swiss Chard with Spicy Garlic Oil</h4>
                                <ul>
                                    <li>Swiss Chard or Tuscan Kale</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Swiss chard, silver or spinach beet, is a leafy green vegetable enjoyed for centuries. This plant is native to the Mediterranean region and was first cultivated by the ancient Greeks and Romans. It was highly valued for its medicinal properties and was used to treat a variety of ailments.</p>
                        <p>Today, Swiss chard is still considered a superfood packed with vitamins A, C, and K, calcium, iron, and fiber. It is believed to have anti-inflammatory properties and may help to reduce the risk of chronic diseases such as heart disease and cancer. Additionally, Swiss chard is low in calories and can be enjoyed in various dishes, making it a versatile and healthy addition to any diet.</p>
                        <p>Website - <a href="https://www.foodnetwork.com/recipes/aaron-mccargo-jr/spicy-swiss-chard-recipe-1945428" target="_blank" rel="noopener noreferrer" className="text-info">Spicy Swiss Chard Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/potato-salad-with-parsley-garlic.png`} className="w-100" alt="The Recipe Challenge: Potato Salad with Parsley and Garlic" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Potato Salad with Parsley &amp; Garlic</h4>
                                <ul>
                                    <li>Yellow Potato and Onions</li>
                                    <li>Parsley</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The potato was the first domesticated vegetable in the region of modern-day southern Peru and extreme northwestern Bolivia between 8000 and 5000 BCE.</p>
                        <p>Parsley, scientifically known as Petroselinum crispum, belongs to the Apiaceae family and is native to Greece, Morocco, and the former Yugoslavia. It has successfully been introduced and naturalized in various regions with favorable climates. This versatile plant is cultivated globally for its culinary uses as both an herb and a vegetable.</p>
                        <p>Website - <a href="https://easyhealthyrecipes.com/garlic-butter-parsley-potatoes/" target="_blank" rel="noopener noreferrer" className="text-info">Garlic Butter Parsley Potatoes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.tastingtable.com/648173/the-best-parsley-potatoes-recipe/" target="_blank" rel="noopener noreferrer" className="text-info">The Best Parsley Potatoes Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/micro-greens-oyster-mushrooms.png`} className="w-100" alt="The Recipe Challenge: Microgreens & Oyster Mushrooms" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Microgreens &amp; Oyster Mushrooms</h4>
                                <ul>
                                    <li>Microgreens</li>
                                    <li>Mushrooms</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Oyster mushrooms are a type of edible fungi. Different types of oyster mushrooms are found around the world including in the UK and Ireland, most of mainland Europe, Asia, and parts of North America.</p>
                        <p>Oyster mushrooms were first officially documented in 1775 by Nikolaus Joseph Freiherr von Jacquin, a Dutch naturalist.</p>
                        <p>In 1871, a German mycologist named Paul Kummer moved oyster mushrooms to their own genus called Pleurotus, which has remained the scientific name for oyster mushrooms until today.</p>
                        <p>Website - <a href="https://docs.google.com/document/d/1jahOeNqgWrniC-MIzcKrwy4Fpl-UH5ome-pCP96Trxs/mobilebasic" target="_blank" rel="noopener noreferrer" className="text-info">Microgreens &amp; Oyster Mushrooms Recipes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://thishealthytable.com/blog/pan-fried-oyster-mushrooms/" target="_blank" rel="noopener noreferrer" className="text-info">Pan-Fried Oyster Mushrooms <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=8Ec6cBhBKjs" target="_blank" rel="noopener noreferrer" className="text-info">Chrissy Makes Fried Oyster Mushrooms | From the Home Kitchen | Bon Appétit <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/indian-style-mustard-greens.png`} className="w-100" alt="The Recipe Challenge: Indian-Style Mustard Greens (sarson ka saag)" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Indian-Style Mustard Greens (sarson ka saag)</h4>
                                <ul>
                                    <li>Mustard Greens</li>
                                    <li>Spinach</li>
                                    <li>Onions</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Sarson ka saag is a dish of mustard greens cooked with spices. It originated in the north of the Indian subcontinent and is popular throughout the region.</p>
                        <p>Mustard has been cultivated for centuries across Asia and Europe for both its edible seeds, ground and made into mustard (the condiment) or pressed for their oil, along with its leaves (and even stems). Food historian Alan Davidson notes that mustard seeds have been found in ancient Greek archeological sites; ancient Greek historian Herodotus wrote of their cultivation as early as the 5th century BCE.</p>
                        <p>Mustard is super good for you. One cup has over 500 percent of your daily vitamin K intake, necessary for blood and bone health. The greens are also a great source of vitamin A, vitamin C and folate, and contain decent amounts of calcium and manganese.</p>
                        <p>Website - <a href="https://www.foodandwine.com/recipes/indian-style-mustard-greens" target="_blank" rel="noopener noreferrer" className="text-info">Indian-Style Mustard Greens <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://naturallynidhi.com/sarson-ka-saag-spicy-mustard-greens/" target="_blank" rel="noopener noreferrer" className="text-info">Sarson Ka Saag - Spicy Mustard Greens (Instant Pot or Stovetop) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://youtu.be/d8nJW-mA5DI" target="_blank" rel="noopener noreferrer" className="text-info">Sarson ka Saag with Makki ki Roti Recipe by SooperChef <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - <a href="https://youtu.be/KMHi9gmfzS8" target="_blank" rel="noopener noreferrer" className="text-info">Sarson Ka Saag Recipe | Punjabi Style Sarson Ka Saag Banane ka Tarika | सरसों का साग | Winter Recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/yoda-rice-krispie-treat.png`} className="w-100" alt="The Recipe Challenge: Yoda Rice Krispie Treat" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Yoda Rice Krispie Treat</h4>
                                <ul>
                                    <li>Cheat day. Enjoy!</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><b>Yoda (Minch Yoda)</b> is a legendary Jedi Master in the Star Wars universe, known for his exceptional wisdom and mastery of the Force. He served as the Grand Master of the Jedi Order for centuries, training numerous Jedi Knights and leading them in the fight against the dark side of the Force.</p>
                        <p><b>Rice Krispie Treat</b> is a popular snack made with Rice Krispies cereal, marshmallows, and butter. It was first invented in the 1930s and has since become a beloved treat enjoyed by people of all ages.</p>
                        <p>Website - <a href="https://howtomakecerealtreats.com/baby-yoda-rice-krispie-treats/" target="_blank" rel="noopener noreferrer" className="text-info">Baby Yoda Rice Krispie Treats <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.yummly.com/dish/652974/the-best-star-wars-themed-recipes-in-the-galaxy" target="_blank" rel="noopener noreferrer" className="text-info">The Best Star Wars-Themed Eats in the Galaxy <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://youtu.be/y1gKtWyK4IY" target="_blank" rel="noopener noreferrer" className="text-info">Rice Krispies Treats (Classic Version) - Joyofbaking.com <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - <a href="https://youtu.be/EqAxu4_1B3U" target="_blank" rel="noopener noreferrer" className="text-info">1988 Rice Krispies Treats Ad (Aired in 1992) <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/roasted-asparagus.png`} className="w-100" alt="The Recipe Challenge: Roasted Asparagus" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Roasted Asparagus</h4>
                                <ul>
                                    <li>Asparagus</li>
                                    <li>Onion</li>
                                    <li>Thyme</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Asparagus is a perennial vegetable with a rich history of over 2,000 years. Its origins can be traced back to the eastern Mediterranean and Asia Minor regions, where it was a prized delicacy among the nobility.</p>
                        <p>Today, asparagus is grown in many parts of the world and is enjoyed for its delicate flavor and numerous health benefits. It is also versatile in various dishes, from soups and salads to stir-fries and pasta dishes.</p>
                        <p>Website - <a href="https://www.feastingathome.com/asparagus-recipes/" target="_blank" rel="noopener noreferrer" className="text-info">20 Best Asparagus Recipes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.askchefdennis.com/irish-cottage-pie/" target="_blank" rel="noopener noreferrer" className="text-info">Oven-Roasted Asparagus <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://youtu.be/Z0kOYu_V6dY" target="_blank" rel="noopener noreferrer" className="text-info">How to Cook Asparagus <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/cottage-shepherds-pie.png`} className="w-100" alt="The Recipe Challenge:  Cottage / Shepherd's Pie" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Cottage / Shepherd's Pie</h4>
                                <ul>
                                    <li>Potatoes</li>
                                    <li>Onion</li>
                                    <li>Thyme</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The pie is a savoury dish of cooked minced meat topped with mashed potato and baked. </p>
                        <p>Cottage pie is made up of a layer of ground beef covered with a "crust" layer of mashed potatoes. Shepherd's pie is made from lamb ("shepherd").</p>
                        <p>Nearly anyone in the States think the pie is from Ireland. In fact, it's origins in Scotland, in the North of the UK.</p>
                        <p>The term "cottage pie" was in use by 1791. A recipe for "shepherd's pie" published in Edinburgh in 1849.</p>
                        <p>Website - <a href="https://www.curiouscuisiniere.com/cottage-pie/" target="_blank" rel="noopener noreferrer" className="text-info">Cottage Pie <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://tastesbetterfromscratch.com/cottage-pie/" target="_blank" rel="noopener noreferrer" className="text-info">Cottage Pie<FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.askchefdennis.com/irish-cottage-pie/" target="_blank" rel="noopener noreferrer" className="text-info">Irish Cottage Pie (Shepherd's Pie) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://youtu.be/QZSzoSX942o" target="_blank" rel="noopener noreferrer" className="text-info">Chef Billy Parisi - Cottage Pie<FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/baby-greens.png`} className="w-100" alt="The Recipe Challenge: Baby Greens" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Baby Greens</h4>
                                <ul>
                                    <li>Spinach</li>
                                    <li>Kale</li>
                                    <li>Arugula</li>
                                    <li>Mix Microgreens</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Microgreens began showing up on chefs' menus as early as the 1980s in San Francisco. In Southern California, microgreens have been grown since the mid-90s.</p>
                        <p>Microgreens, sprouts, and baby greens are plants that differ based on the time of harvest. Microgreens are smaller than “baby greens”, but they are larger and harvested later than the younger “sprouts”.</p>
                        <p>Website - <a href="https://thecrunchyginger.com/10-tips-for-using-spring-greens/" target="_blank" rel="noopener noreferrer" className="text-info">10 Tips for Using Spring Greens <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.acouplecooks.com/spring-mix-salad/" target="_blank" rel="noopener noreferrer" className="text-info">Simple Spring Mix Salad <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.williams-sonoma.com/recipe/stir-fried-baby-greens-with-ginger-and-garlic.html" target="_blank" rel="noopener noreferrer" className="text-info">Stir-Fried Baby Greens with Ginger and Garlic <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=8mWyKx14h54&t=1s" target="_blank" rel="noopener noreferrer" className="text-info">How to Build a Perfect Salad in a Jar <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/soup-joumou.png`} className="w-100" alt="The Recipe Challenge: Soup Joumou (Haitian Squash Soup)" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Soup Joumou (Haitian Squash Soup)</h4>
                                <ul>
                                    <li>Butternut/Pumpkin</li>
                                    <li>Carrot</li>
                                    <li>Cabbage</li>
                                    <li>Onion/Scallions</li>
                                    <li>Potatoes</li>
                                    <li>Peppers</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Soup Joumou is a soup native to Haitian cuisine served every year on the 1st of January to celebrate their Independence.</p>
                        <p>In 2021, soup joumou was added to the UNESCO Intangible Cultural Heritage List. The soup is traditionally cooked with winter squashes such as the turban squash. </p>
                        <p>Website - <a href="https://www.epicurious.com/recipes/food/views/haitian-beef-and-pumpkin-soup-soup-joumou" target="_blank" rel="noopener noreferrer" className="text-info">Haitian Beef and Pumpkin Soup (Soup Joumou) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=Tx04BiIGNOI" target="_blank" rel="noopener noreferrer" className="text-info">HOW TO MAKE HAITIAN JOUMOU aka SQUASH SOUP <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Website - <a href="https://www.savorythoughts.com/soup-joumou-haitian-squash-soup/" target="_blank" rel="noopener noreferrer" className="text-info">Soup Joumou <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=GLIzhhxWbgw" target="_blank" rel="noopener noreferrer" className="text-info">Savory Thoughts - Haitian Soup Joumou (Haitian Pumpkin Soup) <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/potato-latkes.png`} className="w-100" alt="The Recipe Challenge: Potato Latkes" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Potato Latkes</h4>
                                <ul>
                                    <li>Sunchokes</li>
                                    <li>Onion</li>
                                    <li>Egg</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The potato was the first domesticated vegetable in the region of modern-day southern Peru and extreme northwestern Bolivia between 8000 and 5000 BCE.</p>
                        <p>There are more than 4,000 varieties of native potatoes, mostly found in the Andes and more than 200 varieties of potatoes sold throughout the United States. </p>
                        <p>A <b>latke</b> is a type of potato pancake or fritter found in Ashkenazi Jewish cuisine.</p>
                        <p>Website - <a href="https://www.allrecipes.com/recipe/16073/potato-latkes-i/" target="_blank" rel="noopener noreferrer" className="text-info">Potato Latkes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.thekitchn.com/how-to-make-latkes-at-home-251997" target="_blank" rel="noopener noreferrer" className="text-info">How To Make Classic Latkes: The Easiest, Simplest Method <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=PjfzcHJRnJE" target="_blank" rel="noopener noreferrer" className="text-info">How To Cook Latkes for Hanukkah || Mayim Bialik <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/mashed-sunchokes.png`} className="w-100" alt="The Recipe Challenge: Mashed Sunchokes with Thyme" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Mashed Sunchokes with Thyme</h4>
                                <ul>
                                    <li>Sunchokes</li>
                                    <li>Thyme</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The Jerusalem artichoke, also called sunroot, sunchoke, wild sunflower, topinambur, or earth apple, is a species of sunflower native to central North America. Native Americans grew the tubers for food long before the arrival of Europeans. </p>
                        <p>The plant has a yellow flower above ground, making it decorative as well as edible. The root vegetable can be eaten raw, cooked, or pickled.</p>
                        <p>Website - <a href="https://www.seriouseats.com/smashed-sunchokes-with-thyme-butter" target="_blank" rel="noopener noreferrer" className="text-info">Smashed Sunchokes With Thyme-Butter Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - <a href="https://www.youtube.com/watch?v=aS3dMa4z6Tg" target="_blank" rel="noopener noreferrer" className="text-info">Simple and Delicious Smashed Sunchoke (or Jerusalem Artichoke) Recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/celery-soup.png`} className="w-100" alt="The Recipe Challenge: Celery Soup" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Celery Soup</h4>
                                <ul>
                                    <li>Celery</li>
                                    <li>Dill</li>
                                    <li>Parsley</li>
                                    <li>Onions</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>The name "celery" retraces the plant's route of successive adoption in European cooking, as the English "celery" (1664) is derived from the French céleri coming from the Lombard term, seleri, from the Latin selinon, borrowed from Greek.</p>
                        <p>Celery contains a plant compound called apigenin, which plays a role in traditional Chinese medicine as an anti-inflammatory, antibacterial, antiviral, and antioxidant agent.</p>
                        <p>Website - <a href="https://www.feastingathome.com/celery-soup/" target="_blank" rel="noopener noreferrer" className="text-info">Simple Celery Soup <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.webmd.com/diet/health-benefits-celery" target="_blank" rel="noopener noreferrer" className="text-info">Health Benefits of Celery <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/butternut-squash-tuscan-kale-soup.png`} className="w-100" alt="The Recipe Challenge: Butternut Squash &amp; Tuscan Kale Soup" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Butternut Squash &amp; Tuscan Kale Soup</h4>
                                <ul>
                                    <li>Butternut Squash</li>
                                    <li>Tuscan Kale</li>
                                    <li>Onions</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Butternut squash is high in potassium which helps prevent high blood pressure and asthma, lower cancer risk, and manage diabetes.</p>
                        <p>PLacinato kale goes by many names, including Tuscan kale, dinosaur kale and black kale, from its Italian name "cavolo nero." It has long, dark green, highly textured leaves with a tangy cabbage flavor.</p>
                        <p>Website - <a href="https://dishingouthealth.com/kale-and-white-bean-soup/" target="_blank" rel="noopener noreferrer" className="text-info">Butternut Squash, Kale and White Bean Soup <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Chef Benia <a href="https://www.instagram.com/reel/CjTa7rZpwZK/" target="_blank" rel="noopener noreferrer" className="text-info">Live Cook Demo - Butternut Squash &amp; Tuscan Kale Soup <FontAwesomeIcon icon={faInstagram} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/poblano-peppers.png`} className="w-100" alt="The Recipe Challenge: Poblano Peppers" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Poblano Peppers</h4>
                                <ul>
                                    <li>Poblano Peppers</li>
                                    <li>Corn</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Poblanos (pronounced po-BLAH-no) peppers are a mild variety of chile pepper named after the Mexican state of Puebla where they were first grown.</p>

                        <p>Ancho chiles (dried poblanos) can be ground and used as a spice or soaked and pureed in sauces such as mole poblano.</p>

                        <p>Poblano peppers are rich in vitamin C and in vitamin A.  </p>
                        <p>Website - <a href="https://www.foodnetwork.com/recipes/alexandra-guarnaschelli/stuffed-poblano-peppers-3185125" target="_blank" rel="noopener noreferrer" className="text-info">Stuffed Poblano Peppers <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Rachel cooks with love <a href="https://www.youtube.com/watch?v=NIhBUzMF8qY" target="_blank" rel="noopener noreferrer" className="text-info">Cleaned, Roasted And Peeled Poblano Peppers <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/heirloom-bruschetta.png`} className="w-100" alt="The Recipe Challenge: Heirloom Bruschetta" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Heirloom Bruschetta</h4>
                                <ul>
                                    <li>Heirloom Tomatoes</li>
                                    <li>Red Onions</li>
                                    <li>Basil</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>An heirloom tomato (also called heritage tomato in the UK) is an open-pollinated, non-hybrid heirloom cultivar of tomato. </p>
                        <p>The tomato is native to Mexico and Central America where it was cultivated by the Aztecs and Incas as early as 700 AD. It was introduced to Spain in the 16th century and in the mid 1800’s in the U.S. , although some Native American tribes and the Creoles of New Orleans already had a long history of its use.</p>
                        <p>Website - <a href="https://www.onceuponachef.com/recipes/bruschetta-with-heirloom-tomatoes-olives-and-basil.html" target="_blank" rel="noopener noreferrer" className="text-info">Bruschetta with Heirloom Tomatoes, Olives and Basil <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://marinmamacooks.com/grilled-bruschetta-with-heirloom-tomatoes/" target="_blank" rel="noopener noreferrer" className="text-info">Grilled Bruschetta with Heirloom Tomatoes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Joshua Walbolt <a href="https://www.youtube.com/watch?v=aVYPRlzq8VE" target="_blank" rel="noopener noreferrer" className="text-info">Heirloom Tomato Bruschetta <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - PBS North Carolina <a href="https://www.youtube.com/watch?v=xoxM2d-bwXE" target="_blank" rel="noopener noreferrer" className="text-info">Lady Pea &amp; Heirloom Tomato Salad <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/lettuce-wraps.png`} className="w-100" alt="The Recipe Challenge: Lettuce Wrap" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Lettuce Wrap</h4>
                                <ul>
                                    <li>Lettuce</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Lettuce originated in the Mediterranean area and was first grown as a weed. The first documentation of cultivation begins in Ancient Egypt over 6,000 years ago, but it may have been cultivated in the Middle East prior to this. Ancient Egyptian artwork, especially tomb paintings, depicts different varieties of lettuce.</p>
                        <p>Lettuce is a member of the sunflower family <br />Lettuce leaves that are darker in color contain more vitamins and minerals than the paler color varieties.</p>
                        <p>Website - <a href="https://www.yummly.com/recipes/romaine-lettuce-wraps" target="_blank" rel="noopener noreferrer" className="text-info">What are your favorite cuisines? <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://dpi.wi.gov/sites/default/files/imce/school-nutrition/pdf/fact-sheet-lettuce.pdf" target="_blank" rel="noopener noreferrer" className="text-info">Fact Sheet - Lettuce <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Archana's Kitchen <a href="https://www.youtube.com/watch?v=DlIcqFGHmjE" target="_blank" rel="noopener noreferrer" className="text-info">P. F. Chang Style Vegetarian Lettuce Wrap Recipe by Archanas Kitchen <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Video - Ashees cookbook <a href="https://www.youtube.com/watch?v=DlIcqFGHmjE" target="_blank" rel="noopener noreferrer" className="text-info">Chicken Lettuce wrap <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/corn-salsa.png`} className="w-100" alt="The Recipe Challenge: Corn Salsa" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Corn Salsa</h4>
                                <ul>
                                    <li>Corn</li>
                                    <li>Onions</li>
                                    <li>Tomatoes</li>
                                    <li>Jalapeno</li>
                                    <li>Cilantro</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Corn originated in Mexico over 9,000 years ago and is known by its original name "maize" in many parts of the world. Native Americans grew and harvested this crop as a main source of food.</p>
                        <p>Corn is rich in fiber, vitamins and minerals.</p>
                        <p>Website - <a href="https://www.allrecipes.com/recipe/187625/easy-corn-salsa/" target="_blank" rel="noopener noreferrer" className="text-info">Corn Salsa <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://emergencemagazine.org/feature/corn-tastes-better/" target="_blank" rel="noopener noreferrer" className="text-info">Story of Corn <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - beHealthybeHappy <a href="https://www.youtube.com/watch?v=sid38jyNmM4" target="_blank" rel="noopener noreferrer" className="text-info"> Healthy Green Cabbage, carrots, and fresh dill salad - Coleslaw without mayo  <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/creamy-carrot-cabbage-dill-coleslaw.png`} className="w-100" alt="The Recipe Challenge: Creamy Carrot, Cabbage &amp; Dill Coleslaw" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Creamy Carrot, Cabbage &amp; Dill Coleslaw</h4>
                                <ul>
                                    <li>Cabbage</li>
                                    <li>Carrot</li>
                                    <li>Dill</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Cabbage can be a very good source of dietary fiber, calcium, magnesium, and potassium. As per the USDA, it can also contain various vitamins like vitamin C, thiamine, niacin, and folate. It may also be high in antioxidants including flavonoids, zeaxanthin, lutein, choline, and beta-carotene.</p>
                        <p>The cultivated cabbage originated somewhere in Europe more than 2000 years ago, and has become a common staple in cuisines around the world. Its ubiquity in our own markets and on American dinner tables is probably why "cabbage" is also versatile as a figure of speech, with dozens of slang meanings (many of them unprintable here).
                            <br />The word cabbage is related to the French word caboche, which also means "blockhead" or "moron," and seems to be the origin of the pejorative "cabbagehead" ("moron"). <a href="https://www.almanac.com/5-reasons-you-should-eat-cabbage" target="_blank" rel="noopener noreferrer" className="text-info">Article <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.organicfacts.net/health-benefits/vegetable/health-benefits-of-cabbage.html" target="_blank" rel="noopener noreferrer" className="text-info">12 Proven Health Benefits &amp; Uses of Cabbage <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="http://www.paleopantry.org/creamy-carrot-cabbage-and-dill-coleslaw/" target="_blank" rel="noopener noreferrer" className="text-info">Creamy Carrot, Cabbage &amp; Dill Coleslaw <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Website - <a href="https://www.allrecipes.com/recipe/268732/perfect-cabbage-with-dill/" target="_blank" rel="noopener noreferrer" className="text-info">Perfect Cabbage with Dill <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - beHealthybeHappy <a href="https://www.youtube.com/watch?v=sid38jyNmM4" target="_blank" rel="noopener noreferrer" className="text-info"> Healthy Green Cabbage, carrots, and fresh dill salad - Coleslaw without mayo  <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/roasted-stuffed-squash-blossoms.png`} className="w-100" alt="The Recipe Challenge: Roasted Stuffed Squash Blossoms" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Roasted Stuffed Squash Blossoms</h4>
                                <ul>
                                    <li>Squash Blossoms</li>
                                    <li>Garlic Scapes</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Squash blossoms (called courgette flowers) are the edible flowers of Cucurbita species, particularly Cucurbita pepo, the species that produces zucchini (courgette), marrow, spaghetti squash, and many other types of squash.</p>
                        <p>Website - <a href="https://www.allrecipes.com/recipe/254227/oven-roasted-stuffed-squash-blossoms/" target="_blank" rel="noopener noreferrer" className="text-info">How To Make Roasted Stuffed Squash Blossoms <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Food Network <a href="https://www.youtube.com/watch?v=RGv_tThcWZs" target="_blank" rel="noopener noreferrer" className="text-info">Michael Symon's Stuffed Squash Blossoms <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/stuffed-round-zucchini.png`} className="w-100" alt="The Recipe Challenge: Stuffed Round Zucchini" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Stuffed Round Zucchini</h4>
                                <ul>
                                    <li>Zucchini</li>
                                    <li>Parsley</li>
                                    <li>Garlic</li>
                                    <li>Onion</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Round zucchini are simply just a variety of the zucchini squash.</p>
                        <p>Raw zucchini is generally safe to eat, it is packed with many important vitamins, minerals, and antioxidants. It has a high fiber content and a low calorie count.</p>

                        <p>Website - <a href="https://www.lowcarbsosimple.com/stuffed-round-zucchini/" target="_blank" rel="noopener noreferrer" className="text-info">Stuffed Round Zucchini Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Video - Your Guardian Chef <a href="https://www.youtube.com/watch?v=8LSSrE7wsE4" target="_blank" rel="noopener noreferrer" className="text-info">Stuffed Round Zucchini <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/callaloo-jamaican-style.png`} className="w-100" alt="The Recipe Challenge: Callaloo Jamaican Style" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Callaloo Jamaican Style</h4>
                                <ul>
                                    <li>Callaloo / Lambsquarters</li>
                                    <li>Onions</li>
                                    <li>Spring Garlic</li>
                                    <li>Thyme</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Callaloo also known as bhajgee (bah-gee) first appears in Jamaican records as early as 1696 created by enslaved Africans using ideas of the indigenous people along with both African (okra) and indigenous (Xanthosoma) plants.</p>
                        <p>Callaloo has tons of vitamin A, which helps to keep your hair moisturized. </p>

                        <p>Recipe - website <a href="https://www.africanbites.com/callaloo-jamaican-style/" target="_blank" rel="noopener noreferrer" className="text-info">How To Make Jamaican Callaloo <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Recipe - website <a href="https://jamaicanfoodsandrecipes.com/jamaican-callaloo-with-saltfish-recipe/" target="_blank" rel="noopener noreferrer" className="text-info">Jamaican Callaloo with Saltfish Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Recipe video - Chef Ali <a href="https://www.youtube.com/watch?v=otlombbupkY" target="_blank" rel="noopener noreferrer" className="text-info">Jamaican Callaloo Recipe | Callaloo Recipe <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Recipe video - Deddy's Kitchen <a href="https://www.youtube.com/watch?v=8LSSrE7wsE4" target="_blank" rel="noopener noreferrer" className="text-info">How to make Callaloo &amp; Saltfish <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/lemon-balm-pesto.png`} className="w-100" alt="The Recipe Challenge: Lemon Balm Basil Pesto" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Lemon Balm Basil Pesto</h4>
                                <ul>
                                    <li>Lemon Balm</li>
                                    <li>Parsley</li>
                                    <li>Garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Lemon balm (Melissa officinalis) is an herb from the mint family, is considered a calming herb.</p>
                        <p>Lemon balm reduce stress and anxiety, promote sleep, improve appetite, and ease pain and discomfort from indigestion (including gas and bloating, as well as colic).</p>

                        <p>Article - Mount Sinai <a href="https://www.mountsinai.org/health-library/herb/lemon-balm#:~:text=Lemon%20balm%20(Melissa%20officinalis)%2C,%2C%20as%20well%20as%20colic).Q" target="_blank" rel="noopener noreferrer" className="text-info">Lemon balm <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Recipe website - Fork in the Kitchen <a href="https://www.forkinthekitchen.com/lemon-basil-pesto/" target="_blank" rel="noopener noreferrer" className="text-info">LEMON BASIL CITRUS PESTO <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/pea-puree-with-mint-and-chives.png`} className="w-100" alt="The Recipe Challenge: Pea Purée with Mint and Chives" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Pea Purée with Mint and Chives</h4>
                                <ul>
                                    <li>Chives</li>
                                    <li>Mint</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Chives, or <em>Allium schoenoprasum</em>, herb is native to Europe, North America, and parts of Asia where it's a very common seasoning in Asian cuisine.<br />
                            Chives contain nutrients that may treat digestive issues, boost immunity, and bone and heart health. </p>

                        <p>Mint belong to the genus <em>Mentha</em>. Mint is a particularly good source of vitamin A, a fat-soluble vitamin and a potent source of antioxidants.</p>
                        <p>Recipe video - Chef Lee Chizmar <a href="https://www.youtube.com/watch?v=4Al1Wz_VkqQ" target="_blank" rel="noopener noreferrer" className="text-info">Pea Purée  <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Recipe video - Healthy Heart Chefs <a href="https://www.youtube.com/watch?v=4Al1Wz_VkqQ" target="_blank" rel="noopener noreferrer" className="text-info">How To Store Chives Longer | How To Keep Chives Fresh <FontAwesomeIcon icon={faYoutube} /></a></p>
                        <p>Recipe video - Sweetest Banana <a href="https://food52.com/recipes/23572-kale-spinach-and-apple-salad-with-mint-chive-dressing" target="_blank" rel="noopener noreferrer" className="text-info">How To Freeze Mint Leaves <FontAwesomeIcon icon={faYoutube} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/bok-choy.png`} className="w-100" alt="The Recipe Challenge: Bok Choy With Garlic" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Bok Choy With Garlic and Oyster Sauce</h4>
                                <ul>
                                    <li>bok choy</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Shanghai bok choy</strong> is also called white Chinese cabbage, pak choi or pok choi, is one of two main types of leafy green vegetable.</p>
                        <p>According to the United States Department of Agriculture (USDA) National Nutrient Database, 1 cup of raw bok choy, weighing 70 grams (g) contains: 9 calories. 1.05 g of protein. 1.53 g of carbohydrates.</p>
                        <p>Recipe video - Pickled Plum <a href="https://www.youtube.com/watch?v=vuDrEPcSpzA" target="_blank" rel="noopener noreferrer" className="text-info">Bok Choy with Garlic and Oyster Sauce <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Recipe website -  Chef Zee Cooks <a href="https://pickledplum.com/bok-choy-garlic-oyster-sauce/" target="_blank" rel="noopener noreferrer" className="text-info">Bok Choy With Garlic and Oyster Sauce <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/chimichurri-sauce.png`} className="w-100" alt="The Recipe Challenge: Chimichurri Sauce" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Chimichurri Sauce</h4>
                                <ul>
                                    <li>Kale</li>
                                    <li>Parsley</li>
                                    <li>Cilantro</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Chimichurri</strong> is a condiment or marinade similar to pesto but rather it's parsley and vinegar based.</p>
                        <p>It's a common dish that is found in South American and Central American countries such as Argentina and Mexico.</p>
                        <p>Recipe video -  Chef Zee Cooks <a href="https://youtu.be/6WCK3lxI6HA?t=55" target="_blank" rel="noopener noreferrer" className="text-info">How to Make Chimichurri Sauce <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                        <p>Recipe website -  Chef Zee Cooks <a href="https://www.chefzeecooks.com/chimichurri-sauce/" target="_blank" rel="noopener noreferrer" className="text-info">Chimichurri Sauce <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/kale-onion.png`} className="w-100" alt="The Recipe Challenge: Kale With Caramelized Onions and Garlic" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Kale With Caramelized Onions and Garlic</h4>
                                <ul>
                                    <li>Kale</li>
                                    <li>Onions (yellow)</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Kale</strong>  is a green popular leafy, cruciferous vegetable and a member of the cabbage family that is rich in nutrients.</p>
                        <p>A single cup of raw kale contains Vitamin A, Vitamin K, Vitamin C, Vitamin B6, Manganese, Calcium, Copper, Potassium and Magnesium</p>
                        <p>Recipe from - food <a href="https://www.food.com/recipe/kale-with-caramelized-onions-and-garlic-51780" target="_blank" rel="noopener noreferrer" className="text-info">Kale With Caramelized Onions and Garlic Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/mashed-turnips.png`} className="w-100" alt="The Recipe Challenge: Mashed Turnips with Potatoes" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Mashed Turnips with Potatoes</h4>
                                <ul>
                                    <li>turnips</li>
                                    <li>potatoes (yellow)</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Turnips</strong> are a root vegetable commonly associated with potatoes or beets, but their closest relatives are radishes and arugula, and grows especially in cooler climates.</p>
                        <p><strong>Boiled turnip greens</strong> are a rich source of vitamin K, A, C, and B9 and contain substantial lutein.</p>
                        <p>
                            Recipe video from - OklahomaGardening <a href="https://youtu.be/ilFuKpP-qks" target="_blank" rel="noopener noreferrer" className="text-info">Mashed Potatoes with a Turnip Twist <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />
                            Recipe video from - Masala Herb <a href="https://www.youtube.com/watch?v=geUfhQB2zEI" target="_blank" rel="noopener noreferrer" className="text-info">Low Carb Mashed Turnips <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />
                            Recipe from - Masala Herb <a href="https://www.masalaherb.com/mashed-turnips/" target="_blank" rel="noopener noreferrer" className="text-info">Mashed Turnips Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/baby-green-salad.png`} className="w-100" alt="The Recipe Challenge: Baby Green Salad" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Baby Green Salad</h4>
                                <ul>
                                    <li>Spinach</li>
                                    <li>Arugula</li>
                                    <li>Kale</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Baby Greens</strong> are good sources of Vitamins A, C, and K, minerals calcium and potassium as well.</p>
                        <p>
                            Recipe from - <a href="https://www.lecremedelacrumb.com/best-simple-tossed-green-salad/" target="_blank" rel="noopener noreferrer" className="text-info">Creme De La Crumb <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />
                            Recipe from - <a href="https://yummly.com/recipe/Baby-Green-Salad-Food_com-150719" target="_blank" rel="noopener noreferrer" className="text-info">Yummly <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/sweet_potato_kale_spanish_frittata.png`} className="w-100" alt="The Recipe Challenge: Sweet Potato and Kale Spanish Frittata" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Sweet Potato and Kale Spanish Frittata</h4>
                                <ul>
                                    <li>Free-Range Eggs <br /><em>(Triple J Farm NY)</em></li>
                                    <li>Sweet Potato</li>
                                    <li>Kale</li>
                                </ul>
                            </Col>
                        </Row>
                        <p><strong>Frittata</strong> is an egg-based Italian dish similar to an omelette or crustless quiche or scrambled eggs, enriched with additional ingredients such as meats, cheeses, or vegetables.</p>
                        <p>Eggs also contain decent amounts of vitamin D, vitamin E, vitamin K, vitamin B6, calcium and zinc.</p>
                        <p>Free-range eggs - Hens are in a cage-free environment, have access to the outdoors during their laying cycle and carry the USDA shield.</p>
                        <p>Recipe from - <a href="https://www.foodnetwork.com/recipes/giada-de-laurentiis/sweet-potato-and-kale-spanish-frittata-5477214" target="_blank" rel="noopener noreferrer" className="text-info">foodnetwork <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/omelette.png`} className="w-100" alt="The Recipe Challenge: Omelette" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Omelette</h4>
                                <ul>
                                    <li>Free-Range Eggs <br /><em>(Triple J Farm NY)</em></li>
                                    <li>Mushrooms</li>
                                    <li>Spinach</li>
                                    <li>Peppers</li>
                                    <li>Garlic &amp; Herbs</li>
                                    <li>Potatoes</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Eggs also contain decent amounts of vitamin D, vitamin E, vitamin K, vitamin B6, calcium and zinc.</p>
                        <p>Free-range eggs - Hens are in a cage-free environment, have access to the outdoors during their laying cycle and carry the USDA shield.</p>
                        <p>Recipe from - <a href="https://www.instagram.com/seasonedspiced/" target="_blank" rel="noopener noreferrer" className="text-info">@seasonedspiced <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/beets-carrots_salad.png`} className="w-100" alt="The Recipe Challenge: Roasted Beets and Carrots &amp; Kale Salad" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Roasted Beets and Carrots &amp; Kale Salad</h4>
                                <ul>
                                    <li>beets</li>
                                    <li>carrots</li>
                                    <li>kale</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Beets lower the risk of common diabetes complications, including nerve damage and eye damage.</p>
                        <p>Beet and Carrot salad is full of vitamins and minerals, fiber and healthy fats.</p>
                        <p>hauteandhealthyliving - <a href="https://www.hauteandhealthyliving.com/roasted-beet-carrot-kale-salad/" target="_blank" rel="noopener noreferrer" className="text-info">Roasted Beets and Carrots &amp; Kale Salad Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />youtube - <a href="https://www.youtube.com/watch?v=9GJfVCWHjes" target="_blank" rel="noopener noreferrer" className="text-info">How to Make Roasted Beets Recipe <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/carrot-snack-sticks.png`} className="w-100" alt="The Recipe Challenge: Carrot Snack Sticks" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Carrot Snack Sticks</h4>
                                <ul>
                                    <li>carrots</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Carrots have a wealth of antioxidants and offer many health benefits.</p>
                        <p>The fiber in carrots can help keep blood sugar levels under control, contains vitamin A and beta-carotene, to help lower your diabetes risk and strengthen bones.</p>
                        <p>weelicious - <a href="https://weelicious.com/carrot-snack-sticks/" target="_blank" rel="noopener noreferrer" className="text-info">Carrot Snack Sticks <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />foodandwine - <a href="https://www.foodandwine.com/vegetables/root-vegetables/carrot/carrots" target="_blank" rel="noopener noreferrer" className="text-info">28 Carrot Recipes We Love <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />acouplecooks - <a href="https://www.acouplecooks.com/easy-carrot-recipes/" target="_blank" rel="noopener noreferrer" className="text-info">20 Easy Carrot Recipes <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/butternut-squash_sweet-potato-mash.png`} className="w-100" alt="The Recipe Challenge: Butternut Squash and Sweet Potato Mash" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Butternut Squash &amp; Sweet Potato Mash</h4>
                                <ul>
                                    <li>butternut squash</li>
                                    <li>sweet potatoes</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Butternut squash is high in potassium which helps prevent high blood pressure and asthma, lower cancer risk, and manage diabetes.</p>
                        <p>Sweet potatoes are a great source of fiber, antioxidants, vitamin C, vitamin A, potassium and minerals.</p>
                        <p>healthiersteps - <a href="https://www.tasteofhome.com/collection/recipes-to-make-with-apple-cider/" target="_blank" rel="noopener noreferrer" className="text-info">How To Peel And Chop Butternut Squash <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />Butternut Squash &amp; Sweet Potato Mash <a href="https://youtu.be/VBpP-gVj1yI?t=124" target="_blank" rel="noopener noreferrer" className="text-info">Youtube Video <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/apple-cider-challenge.png`} className="w-100" alt="The Recipe Challenge: Apple Cider Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Apple Cider</h4>
                                <ul>
                                    <li>apple cider</li>
                                </ul>
                            </Col>
                        </Row>
                        <blockquote>Apple cider contains polyphenols, which are compounds in plants that act as antioxidants.  They can help the body to fight against free radicals and cell damage, lowering your risk of certain types of cancer, diabetes, and heart disease. Polyphenols also help to ease inflammation in the body. -webmd</blockquote>
                        <p>tasteofhome - <a href="https://www.tasteofhome.com/collection/recipes-to-make-with-apple-cider/" target="_blank" rel="noopener noreferrer" className="text-info">50 Recipes to Make with Apple Cider <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />Apple Cider Vinegar Recipe || Organic ACV with Mother making at Home <a href="https://www.youtube.com/watch?v=kb6EqTPsShc" target="_blank" rel="noopener noreferrer" className="text-info">Youtube Video <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/onion-jam-challenge.png`} className="w-100" alt="The Recipe Challenge: Sauteed Potatoes with Kale Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Onion Jam</h4>
                                <ul>
                                    <li>yellow onions</li>
                                    <li>red onions (sweet)</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Many archaeologists, botanists, and food historians believe onions originated in central Asia. Other research suggests onions were first grown in Iran and West Pakistan. </p>
                        <p>Onions are high in vitamins, minerals, and antioxidants while being low in calories.</p>
                        <p>allrecipes - <a href="https://www.allrecipes.com/recipe/220546/onion-jam/" target="_blank" rel="noopener noreferrer" className="text-info">Onion Jam <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />Youtube Video - <a href="https://www.youtube.com/watch?v=mStklJytmK0&t=9s" target="_blank" rel="noopener noreferrer" className="text-info">3-Ingredient Onion Jam | The Curious Home Cook <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />How to Grow an Onion from an Onion <a href="https://www.wikihow.com/Grow-an-Onion-from-an-Onion" target="_blank" rel="noopener noreferrer" className="text-info">Learn How <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br /><small>For nitrogen fertilizer use finished compost </small>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/potatoes-and-kale-challenge.png`} className="w-100" alt="The Recipe Challenge: Sauteed Potatoes with Kale Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Sauteed Potatoes with Kale</h4>
                                <ul>
                                    <li>potatoes</li>
                                    <li>kale</li>
                                    <li>onion</li>
                                    <li>garlic</li>
                                </ul>
                            </Col>
                        </Row>
                        <p>Potatoes are a good source of fiber, , potassium, vitamin C, and vitamin B6 content, coupled with its lack of cholesterol, all support heart health.</p>
                        <p>Kale contains fiber, antioxidants, calcium, vitamins C and K, iron, and a wide range of other nutrients that can help prevent various health problems.</p>
                        <p>allrecipes - <a href="https://www.allrecipes.com/recipe/272903/sauteed-potatoes-with-kale/" target="_blank" rel="noopener noreferrer" className="text-info">Sauteed Potatoes with Kale <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/tomatillos-challenge.png`} className="w-100" alt="The Recipe Challenge: Roasted Tomatillo Salsa Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Roasted Tomatillo Salsa</h4>
                                <ul>
                                    <li>tomatillos</li>
                                    <li>jalapeño or serrano chilies</li>
                                    <li>onion</li>
                                    <li>cilantro (substitute with papalo)</li>
                                </ul>
                            </Col>
                        </Row>
                        <p className="lead">Tomatillos, also known as the Mexican husk tomato, originated in Mexico and were cultivated in the pre-Columbian era.</p>
                        <p>Tomatillos is a green, unripe tomatoes with a dry leafy husk that wraps around the outside.</p>
                        <p>NYT Cooking - Quick Roasted Tomatillo Salsa By Martha Rose Shulman <a href="https://cooking.nytimes.com/recipes/1014085-quick-roasted-tomatillo-salsa" target="_blank" rel="noopener noreferrer" className="text-info">How to Make Roasted Tomatillo Salsa <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />
                            <a href={`${process.env.PUBLIC_URL}/assets/Roasted-Tomatillo-Salsa-Recipe-NYT-Cooking.pdf`} target="_blank" rel="noopener noreferrer" className="text-info">Download Recipe <FontAwesomeIcon icon={faFilePdf} /></a><br />Youtube Video - <a href="https://www.youtube.com/watch?v=mStklJytmK0&t=9s" target="_blank" rel="noopener noreferrer" className="text-info">Easy Roasted Tomatillo Salsa Verde Recipe - How to Make Roasted Tomatillo Salsa Verde <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/buttercup-soup-challenge.png`} className="w-100" alt="The Recipe Challenge: Stuffed Buttercup Squash Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Stuffed Buttercup Squash</h4>
                                <ul>
                                    <li>buttercup squash</li>
                                    <li>red apples</li>
                                </ul>
                            </Col>
                        </Row>
                        <p className="lead">Buttercup squash has a sweet flavor and is a great source of vitamins A and C, beta-carotene, and fiber.</p>
                        <h5>Ingredients:</h5>
                        <ul>
                            <li>2 buttercup squash, halved and seeded</li>
                            <li>3 cups red apples, peeled and cut into small chunks</li>
                            <li>&#xbd; lemon, juiced</li>
                            <li>&#xbd; cup chopped pecans, divided</li>
                            <li>2 tablespoons brown sugar</li>
                            <li>&#xbd; teaspoon ground cinnamon</li>
                            <li>2 tablespoons butter</li>
                        </ul>
                        <h5>Instructions:</h5>
                        <ol>
                            <li>Preheat oven to 350 degrees F. Place buttercup squash halves in a baking dish.</li>
                            <li>Place apples in a large bowl; cover with lemon juice. Stir in all but 1 tablespoon of the pecans. Stir in brown sugar and cinnamon.</li>
                            <li>Spoon mixture into buttercup squash halves. Sprinkle with reserved pecans. Dot 1 1/2 teaspoons butter over each squash.</li>
                            <li>Bake in the preheat oven until tender, about 45 minutes.</li>
                        </ol>

                        <p>allrecipes - <a href="https://www.allrecipes.com/article/buttercup-squash-choose-prep-cook/" target="_blank" rel="noopener noreferrer" className="text-info">How to Cook Stuffed Buttercup Squash <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/butternut-soup-challenge.png`} className="w-100" alt="The Recipe Challenge: Ratatouille Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Butternut Squash and Leek Soup</h4>
                                <ul>
                                    <li>butternut squash</li>
                                    <li>leeks</li>
                                </ul>
                            </Col>
                        </Row>
                        <p className="lead">Butternut squash, known in Australia and New Zealand as butternut pumpkin or gramma, is a type of pumpkin or winter squash that grows on a vine.</p>
                        <p>Butternut squash is high in potassium which helps prevent high blood pressure and asthma, lower cancer risk, and manage diabetes.</p>
                        <p>myrecipes - <a href="https://www.myrecipes.com/recipe/butternut-squash-leek-soup" target="_blank" rel="noopener noreferrer" className="text-info">How to Make Butternut Squash Leek Soup <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/ratatouille-challenge.png`} className="w-100" alt="The Recipe Challenge: Ratatouille Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Ratatouille</h4>
                                <ul>
                                    <li>eggplants</li>
                                    <li>heirloom tomatoes</li>
                                    <li>white onion</li>
                                    <li>zucchini (yellow &amp; green)</li>
                                    <li>clove garlic</li>
                                    <li>sweet peppers</li>
                                </ul>
                            </Col>
                        </Row>
                        <p className="lead">"Originating in the 18th Century in the Provencal region of Nice, it is known as a peasants stew created by poor farmers that needed to use up the harvest of the fresh summer vegetables that had ripened all at once and were ready to eat from the garden."</p>
                        <p>NYT Ratatouille By Melissa Clark <a href="https://cooking.nytimes.com/recipes/1018511-ratatouille" target="_blank" rel="noopener noreferrer" className="text-info">How to Make Ratatouille <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br />
                            <a href={`${process.env.PUBLIC_URL}/assets/Ratatouille-Recipe-NYT-Cooking.pdf`} target="_blank" rel="noopener noreferrer" className="text-info">Download Recipe <FontAwesomeIcon icon={faFilePdf} /></a>
                        </p>
                    </Col>
                    <Col xs={12} md={6} className="recipe-section mb-3">
                        <Row>
                            <Col xs={12} md={6} className="text-center mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/nutrition/salsa-challenge.png`} className="w-100" alt="The Recipe Challenge: Salsa Challenge" />
                            </Col>
                            <Col xs={12} md={6} className="mb-3">
                                <h4>Salsa</h4>
                                <ul>
                                    <li>tomatoes</li>
                                    <li>medium white onion</li>
                                    <li>small jalapeños</li>
                                    <li>clove garlic (optional)</li>
                                    <li>sprigs fresh cilantro</li>
                                </ul>
                            </Col>
                        </Row>
                        <p className="lead">Salsa is the Spanish word for sauce. <br />Salsa is traced back to the times of the Aztecs, Incas, and Mayans.</p>
                        <ul className="list-unstyled">
                            <li><a href="https://craves.everybodyshops.com/history-of-mexican-salsa/" target="_blank" rel="noopener noreferrer" className="text-info">History of Mexican Salsa<FontAwesomeIcon icon={faExternalLinkAlt} /></a></li>
                            <li><a href="https://www.webstaurantstore.com/blog/3596/salsa-types.html" target="_blank" rel="noopener noreferrer" className="text-info">Types of Salsa<FontAwesomeIcon icon={faExternalLinkAlt} /></a></li>
                            <li><a href="https://www.youtube.com/watch?v=BL61vtJv8kE" target="_blank" rel="noopener noreferrer" className="text-info">Authentic Mexican Salsa Ranchera Recipe<FontAwesomeIcon icon={faExternalLinkAlt} /></a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <h4>Scallion Pancakes</h4>
                        <ul>
                            <li>2 cups flour</li>
                            <li>2 Tbsp toasted sesame oil + more</li>
                            <li>2 cups chopped scallions</li>
                            <li>3/4 boiling water Add boiling water and 2 Tbsp oil to the flour. Knead into a shaggy dough, cover and rest for 1 hour</li>
                            <li>After 1 hour, cut into 8 even pieces</li>
                            <li>Chop up scallions and cilantro</li>
                            <li>Roll out each piece of dough into an oblong shape, brush with sesame oil and fill, salt lightly</li>
                            <li>Roll away from you like a cinnamon role and coil. Pinch to seal</li>
                            <li>Roll out - 7 to 8 inches in diameter</li>
                            <li>Heat a pan on med-low heat, brush with oil and add the pancake.</li>
                            <li>Cook both sides until golden brown, serve with sambal or gyoza sauce.</li>
                        </ul>
                        <p className="lead"><strong>Take a photo and enjoy!</strong>
                        </p>
                        <p><a href="https://www.jumprope.com/g/easy-scallion-pancakes/qlpnuLuL" target="_blank" rel="noopener noreferrer">Recipe Cards <FontAwesomeIcon icon={faExternalLinkAlt} /></a><br /><a href="https://www.tiktok.com/@tiffycooks/video/6939966685000633606" target="_blank" rel="noopener noreferrer">Recipe Video (TikTok) <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default Nutrition;
